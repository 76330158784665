<template>
  <v-card
    class="my-3"
    elevation="6"
    :height="supplier == undefined ? '220px' : '430px'"
  >
    <v-card-title>
      <v-row>
        <h2
          class="my-3 pl-3"
          style="text-transform: uppercase; font-weight: normal"
          v-text="$tc('inventorys.supplier')"
        ></h2>
        <div
          style="position: absolute; top: 13px; right: 25px"
          v-if="inventory.supplier_id != null"
        >
          <v-row>
            <v-col class="pa-0 pt-3">
              <v-select
                outlined
                dense
                clearable
                class="inputs"
                item-value="id"
                :style="
                  $vuetify.breakpoint.xs ? 'width: 100px' : 'width: 130px'
                "
                hide-details
                v-model="supplierChange"
                :items="suppliers"
                item-text="supplier_name"
                return-object
              />
            </v-col>
            <v-col class="pa-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    @click="
                      $router.push({
                        name: 'email',
                        params: { email: inventory.supplier.email },
                      })
                    "
                  >
                    <v-icon small>$email</v-icon>
                  </v-btn>
                </template>
                {{ $t("send", { name: "email" }) }}
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    @click="
                      $router.push({
                        name: 'inventoryList',
                        params: { popup: 'supplier' },
                      })
                    "
                  >
                    <v-icon small>$edit</v-icon>
                  </v-btn>
                </template>
                {{ $t("inventorys.edit_supplier") }}
              </v-tooltip>
            </v-col>
            <!--
            <v-col v-else class="pa-0 pt-1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    style="width: 30px; height: 30px; margin-left: 6px"
                    class="mr-2"
                    icon
                    elevation="0"
                    @click="supplier = null"
                    v-on="on"
                  >
                    <v-icon small>$close</v-icon>
                  </v-btn>
                </template>
                {{ $t("cancel") }}
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    style="width: 30px; height: 30px"
                    icon
                    class="mr-2"
                    elevation="0"
                    type="submit"
                    v-on="on"
                  >
                    <v-icon small>$save</v-icon>
                  </v-btn>
                </template>
                {{ $t("save", { name: "" }) }}
              </v-tooltip>
            </v-col>-->
          </v-row>
        </div>
      </v-row>
    </v-card-title>
    <v-divider> </v-divider>
    <v-simple-table
      style="max-height: 83%; overflow: hidden auto"
      v-if="inventory.supplier_id != null"
      id="infoSupplier"
      class="pa-0"
    >
      <tr>
        <td>{{ $t("inventorys.name_supplier") }}</td>
        <td>{{ inventory.supplier.supplier_name }}</td>
      </tr>
      <tr>
        <td>{{ $t("inventorys.email_facturation") }}</td>
        <td>{{ inventory.supplier.email }}</td>
      </tr>
      <tr>
        <td>{{ $t("inventorys.num_fiscal") }}</td>
        <td>{{ inventory.supplier.num_fiscal }}</td>
      </tr>
      <tr>
        <td>{{ $t("inventorys.direction_fiscal") }}</td>
        <td>{{ inventory.supplier.direction_fiscal }}</td>
      </tr>
      <tr>
        <td>{{ $t("inventorys.city") }}</td>
        <td>{{ inventory.supplier.city }}</td>
      </tr>
      <tr>
        <td>{{ $t("inventorys.postal") }}</td>
        <td>{{ inventory.supplier.postal_cody }}</td>
      </tr>
      <tr>
        <td>{{ $t("inventorys.province") }}</td>
        <td>{{ inventory.supplier.province }}</td>
      </tr>
      <tr>
        <td>{{ $t("inventorys.country") }}</td>
        <td>{{ inventory.supplier.country }}</td>
      </tr>

      <tr>
        <td style="vertical-align: baseline">{{ $t("brands") }}</td>
        <td>
          <ul>
            <li v-for="brand in inventory.supplier.brands" :key="brand">
              {{ brand.brand_name }}
            </li>
          </ul>
        </td>
      </tr>
    </v-simple-table>

    <v-card-text v-else style="max-height: 83%; overflow: hidden auto">
      <v-row
        ><form
          style="width: 98%"
          action
          id="supplierView"
          data-vv-scope="inventory-form"
          @submit.prevent="validateForm('inventory-form')"
        >
          <v-row
            ><v-col class="py-0" cols="7" md="6" xl="4">
              <label>{{ $t("inventorys.supplier") }}</label>
              <v-select
                outlined
                dense
                clearable
                class="inputs"
                item-value="id"
                v-model="supplier"
                :items="suppliers"
                item-text="supplier_name"
                return-object
                @input="supplierValue()"
                validate="required"
                :error_messages="errors.collect('inventory-form.supplier')"
                vv_name="supplier"
              />
            </v-col>
            <v-col cols="5" md="6" class="mt-3" align="right">
              <v-btn
                v-if="supplier != null"
                style="
                  width: 100px !important;
                  color: #363533;
                  height: 25px;
                  padding-top: 10px !important;
                "
                elevation="0"
                type="submit"
              >
                <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
                {{ $t("save", { name: $t("") }) }}
              </v-btn>
            </v-col> </v-row
          ><v-simple-table
            v-if="supplier != null"
            id="infoSupplier"
            class="pa-0"
          >
            <tr>
              <td>{{ $t("inventorys.name_supplier") }}</td>
              <td>
                {{ supplier.supplier_name }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("inventorys.email_facturation") }}</td>
              <td>
                {{ supplier.email }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("inventorys.num_fiscal") }}</td>
              <td>
                {{ supplier.num_fiscal }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("inventorys.direction_fiscal") }}</td>
              <td>
                {{ supplier.direction_fiscal }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("inventorys.city") }}</td>
              <td>
                {{ supplier.city }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("inventorys.postal") }}</td>
              <td>
                {{ supplier.postal_cody }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("inventorys.province") }}</td>
              <td>
                {{ supplier.province }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("inventorys.country") }}</td>
              <td>
                {{ supplier.country }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("brands") }}</td>
              <td>
                <ul>
                  <template v-for="brands in supplier.brands">
                    <li :key="brands">
                      {{ brands.brand_name }}
                    </li>
                  </template>
                </ul>
                <span v-if="supplier.brands.length < 1">-</span>
              </td>
            </tr>
          </v-simple-table>
        </form>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "SuplierCard",
  props: ["inventory"],
  data() {
    return {
      suppliers: [],
      supplier: null,
    };
  },
  computed: {
    supplierChange: {
      set(value) {
        //console.log(value);
        this.$confirm(this.$t("change_event_question_supplier"), "", "").then(
          () => {
            this.inventory.supplier_id = value;
            this.editInventory({
              product_id: this.$route.params.id,
              inventory: this.inventory,
            }).then((response) => {
              if (response) {
                this.errors.clear();

                this.$emit("update");
              }
            });
          }
        );
      },
    },
  },
  components: {
    SupplierEdit: () => import("@/views/supplier/SupplierEdit"),
  },
  mounted() {
    this.fetchAllSuppliers();
  },
  methods: {
    ...mapActions("supplier", ["getAll"]),
    ...mapActions("inventory", ["editInventory"]),
    supplierValue() {
      //console.log(this.supplier);
    },
    async validateForm(scope) {
      this.errors.clear();
      let result = await this.$validator.validateAll(scope);
      if (result) {
        this.inventory.supplier_id = this.supplier;
        //console.log(this.$route.params.id);
        this.editInventory({
          product_id: this.$route.params.id,
          inventory: this.inventory,
        }).then((response) => {
          if (response) {
            this.errors.clear();
            this.$alert(this.$t("inventory_edit_success"), "", "");
            this.$emit("update");
          }
        });
        return true;
      } else {
        return false;
      }
    },

    fetchAllSuppliers() {
      this.getAll().then((data) => {
        this.suppliers = data;
        //console.log(this.suppliers);
      });
    },
  },
};
</script>
<style scoped lang="sass">
.v-data-table
  td
    padding: 8px 8px !important
h2
  font-weight: normal
  font-size: 20px
#supplierView
  label
    display: block
    line-height: normal !important
    height: 25px !important
#infoSupplier

  td:first-child
    width: 250px
    color: var(--v-gris1-base) !important
    td
      max-height: 30px
      padding: 0
      padding-top: 5px

@media (max-width: 400px)
  #infoSupplier

    td:first-child
      width: 150px
      color: var(--v-gris1-base) !important
    td
      max-height: 30px
      padding: 0
      padding-top: 5px
    h2
      font-weight: normal
      font-size: 20px
</style>
